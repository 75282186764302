<template>
    <section>
        <div class="dynCont center">
            <h2>Welcome to 2K Finance - Current Phase: 8Bit</h2>

            <div class="btnGroup flex mw-500">
                <generic-button @clicked="open('information')" title="TWOK - Info"></generic-button>
                <generic-button @clicked="open('dxminformation')" title="Dexmoon - Info"></generic-button>
                <generic-button href="https://exchange.pancakeswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0xae1e69bbd3dc0c470ce4ba28794753cdfdec7452" title="Buy TWOK"></generic-button>                
                <generic-button href="https://exchange.pancakeswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0x7911010aa128e5C2b91E954c821b7fB23ad8ABbc" title="Buy Dexmoon"></generic-button>                
                <generic-button @clicked="open('vault')" title="Vaults"></generic-button>
                <generic-button @clicked="open('charts')" title="Charts"></generic-button> 
                <generic-button @clicked="open('trigger')" title="Ecosystem Triggers"></generic-button>
                <generic-button @clicked="open('partnerships')" title="Partnerships"></generic-button>
                <generic-button @clicked="handleWalletConnect" title="Connect"></generic-button>
                <generic-button @clicked="handleWalletDisconnect" title="Disconnect"></generic-button>
            </div>
        </div>
    </section>
</template>

<script>
// import WalletConnectProvider from "@walletconnect/web3-provider";
import genericButton from "@/components/genericButton";
import useWallet from '../hooks/useWallet';
import Web3 from "web3";

// let isOnLoad = true;

export default {
    name: "welcome",
    components: {genericButton},
    methods: {
        open(key) {
            this.$emit("open", key);
        },
        close(key) {
            this.$emit("close", key);
        },
        async handleWalletConnect() {
            const { onConnect } = useWallet();
            const provider = await onConnect();
            if ( provider != undefined ) {
                this.$store.commit("setProvider", provider);
                this.$store.commit("setWeb3", new Web3( provider ));

                if (provider.selectedAddress != undefined) {
                    this.$store.commit("setAccountAddress", provider.selectedAddress);
                }
                else {
                    this.$store.commit("setAccountAddress", provider.accounts[0]);
                }
            }
        },
        async handleWalletDisconnect() {
            this.$store.commit("setDisconnectMessage", "Disconnected, Please Connect and Refresh!");

            if ( ( this.$store.state && this.$store.state.provider ) != null ) {
                if ( ( this.$store.state.provider && this.$store.state.provider.isMetaMask ) != null ) {
                    this.$store.commit("setDisconnectMessage", "Please Disconnect Wallet From MetaMask Wallet Application, And Refresh");
                    this.open("disconnect-page");
                }

                if ( ( this.$store.state.provider && this.$store.state.provider.connected ) != null ) {
                    await this.$store.state.provider.disconnect();
                    this.open("disconnect-page");
                                    }
            }
            else {
                this.open("disconnect-page");
            }
        }
    },
}
</script>

<style scoped>

</style>
