<template>
    <section>
        <div class="dynCont">
            <h2>Trading Competition</h2>
            <video width="530" height="497" controls>
              <source src="@/assets/video/DMC.mp4" type="video/mp4">
            </video>
        </div>
    </section>
</template>

<script>
export default {
    name: "trading-video"
}
</script>

<style scoped>

</style>
