<template>
    <section class="kk-section">
        <div class="kk-section-top">
            <div class="kk-section-column pt-1 pb-1 pl-3">
                <div class="kk-section-col">
                    <span class="h5 ma-0">Owner Address : </span>
                    <p class="ma-0"> {{ accountAddress }} </p>
                </div>
                <div class="kk-section-col">
                    <span class="h5 ma-0">Coins : </span>
                    <p class="ma-0"> {{ accountBalance.toFixed(4) }} <span class="text-weight"> 2k coin </span> </p>
                </div>
            </div>
        </div>
        <div class="kk-section-cont">
            <loader percentage="35" title="Mined 2 K Coin"></loader>
        </div>
    </section>
</template>

<script>
import loader from "@/components/loader";
export default {
    name: "my-wallet",
    components: { loader },
    updated() {

        if ( this.accountAddress != "" ) {
            let web3 = this.$store.state.web3;

            this.$store.commit("twokVault/setLockToken", web3);
            this.$store.commit("twokVault/setVault", web3);

            setInterval(() => {
                this.$store.dispatch("twokVault/getAccountBalance");
        
                this.accountBalance = this.$store.state.twokVault.accountBalance;
        }, 1000);
            }
    },
    props: {
        accountAddress: String
    },
    data: () => ({
      accountBalance: 0,
    }),
}
</script>

<style scoped>
    .text-weight { font-weight: 600; font-size: initial; }
</style>