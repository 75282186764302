import Web3Modal from 'web3modal';
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

import Fortmatic from "fortmatic";
import Authereum from "authereum";
import Venly from "@venly/web3-provider";
import Torus from "@toruslabs/torus-embed";
// import Onboard from 'bnc-onboard'
// import Web3 from 'web3'
// const Binance = require('node-binance-api');

export default function UseWallet() {
  let web3, provider;
  
  
  // Create a connector
  const connector = new WalletConnect({
    bridge: "https://bridge.walletconnect.org", // Required
    qrcodeModal: QRCodeModal,
  });

  const customNetworkOptions = {
    rpcUrl: 'https://bsc-dataseed.binance.org/',
    chainId: 56
  }

    // const binance = new Binance().options({
    //   APIKEY: 'PPKFWXFCU1A4HGQ6AH1VFNPT8GKZKHSFIS',
    //   // APISECRET: '<secret>'
    // });

  const FORTMATIC_KEY = "pk_live_99AAF094FA38517C"

// const wallets = [
//   { walletName: "coinbase", preferred: true },
//   { walletName: "trust", preferred: true},
//   { walletName: "metamask", preferred: true },
//   { walletName: "authereum" },
//   {
//     walletName: 'trezor',
//     appUrl: APP_URL,
//     email: CONTACT_EMAIL,
//     rpcUrl: RPC_URL
//   },
//   {
//     walletName: 'ledger',
//     rpcUrl: RPC_URL
//   },
//   {
//     walletName: 'lattice',
//     rpcUrl: RPC_URL,
//     appName: APP_NAME
//   },
//   {
//     walletName: 'keepkey',
//     rpcUrl: RPC_URL
//   },
//   { 
//     walletName: 'cobovault',
//     rpcUrl: RPC_URL,
//     appName: APP_NAME,
//   },
//   { 
//     walletName: 'keystone',
//     rpcUrl: RPC_URL,
//     appName: APP_NAME,
//   },
//   {
//     walletName: "fortmatic",
//     apiKey: FORTMATIC_KEY,
//     preferred: true
//   },
//   {
//     walletName: "portis",
//     apiKey: PORTIS_KEY,
//     preferred: true,
//     label: 'Login with Email'
//   },
//   {
//     walletName: "walletConnect",
//     infuraKey: INFURA_KEY,
//     preferred: true
//   },
//   { walletName: "opera" },
//   { walletName: "operaTouch" },
//   { walletName: "torus" },
//   { walletName: "status" },
//   { walletName: "walletLink" , appName: APP_NAME },
//   { walletName: "mykey", rpcUrl: RPC_URL },
//   { walletName: "huobiwallet", rpcUrl: RPC_URL },
//   { walletName: "hyperpay" },
//   { walletName: "wallet.io", rpcUrl: RPC_URL },
//   { walletName: "atoken" },
//   { walletName: "frame" },
//   { walletName: "ownbit" },
//   { walletName: "alphawallet" },
//   { walletName: "gnosis" },
//   { walletName: "xdefi" },
//   { walletName: "bitpie" },
//   { walletName: "binance" },
//   { walletName: "liquality" },
//   { walletName: "tally" },
//   { walletName: "blankwallet" },
//   { walletName: "mathwallet" },
//   { walletName: "ronin" },
// ]


  // const onboard = Onboard({
  //   dappId: "af59bf2a-3612-47b9-ace2-e3db6ff09ca3",       // [String] The API key created by step one above
  //   networkId: 56,  // [Integer] The Ethereum network ID your Dapp uses.
  //   subscriptions: {
  //     wallet: wallet => {
  //        web3 = new Web3(wallet.provider)
  //        console.log(wallet);
  //     }
  //   },
  //   walletSelect: {
  //     wallets
  //   }
  // });  

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "a7f2fa0355d74ff0976dec489b84b3c9",
        rpc: {
          56: 'https://bsc-dataseed1.defibit.io/'
        },
        chainId: 56,
      },
    },
    fortmatic: {
      package: Fortmatic, // required
      options: {
        key: FORTMATIC_KEY,// required,
        network: customNetworkOptions // if we don't pass it, it will default to localhost:8454
      }
    },
    torus: {
      package: Torus,
    },
    authereum: {
      package: Authereum // required
    },
    venly: {
      package: Venly, // required
      options: {
        clientId: "Testaccount-capsule" // required
      }
    },
    binancechainwallet: {
      package: true
    }

  };
  
  //https://github.com/Web3Modal/web3modal#web3modal
  const web3Modal = new Web3Modal({
    theme: "dark",
    network: "mainnet",
    cacheProvider: false,
    providerOptions,
  });

  /**
  * Connect wallet button pressed.
  */

  const onConnect = async () => {
    
    try {
      try {
        provider = await web3Modal.connect();
        // const selectWallet = await onboard.walletSelect("metamask");

        // console.log(selectWallet);

        // const readyToTransact = await onboard.walletCheck();

        // if (readyToTransact) {
        //   const currentState = onboard.getState();

        //   provider = currentState.wallet.provider
        //   console.log(currentState.wallet.provider)
        // }


        // console.log(readyToTransact);

        // if ( !provider.isMetaMask ) {
        //   if (!provider.connector.connected) {
        //     // create new session
        //     provider.connector.createSession();
        //   }
        // }

        // binance.balance((error, balances) => {
        //   if ( error ) return console.error(error);
        //   console.info("balances()", balances);
        //   console.info("ETH balance: ", balances.ETH.available);
        // });

        return provider;
        
      } catch(e) {
        console.log("Could not get a wallet connection", e);
        return;
      }
    } catch (error) {
        console.log(error);
        return;
    }
  };

  return {
    web3Modal,
    onConnect,
    connector,
    provider,
    web3
  };
}
